import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import Layout from "../components/Layout";
import CareerProps from "../components/Repeating/CareerProps";
import Home from "../components/Repeating/career-cta";
import CareerBanner from "../components/Repeating/careerBanner";
import SearchEngineOptimization from "../components/SEO";
import Quote from "../images/0.0 Repeating Modules/Testimonials/quote.svg";

import ButtonSolid from "../components/Button/ButtonSolid";
import careerImage from "../images/common/careers_hero.jpg";
import glassDoor from "../images/common/glass-door-logo.svg";

const Page = ({ data }) => {
  return (
    <Layout>
      <SearchEngineOptimization
      // title="Full-Service Mortgage Lender | Right Start Mortgage"
      // description="At Right Start, we're a full-service mortgage lender who takes the time to get to know you and your goals and then helps you with every step. Get started today!"
      // openGraphImage={data.openGraphImage.publicURL}
      // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      <section className="relative mb-14 overflow-hidden pt-52 pb-22 md:mb-22 md:py-56 ">
        <div className=" absolute left-0 top-0  hidden h-full w-full before:absolute before:inset-0 before:block before:h-full before:w-full before:bg-opacity-10 before:bg-gradient-to-l before:from-[#bdc3c7]/10 before:to-[#001225]/10 md:block ">
          <img
            className="h-full w-full object-cover object-top
          "
            src={careerImage}
          />
        </div>
        <div className="absolute left-0 top-0 h-full w-full md:hidden ">
          <img
            src={careerImage}
            loading="eager"
            className="h-full object-[50%] md:object-top object-cover"
          />
        </div>
        <div className="container ">
          <div className="relative mx-auto max-w-[720px] text-center md:mx-0 md:text-left">
            <div className="mb-4 hidden font-heading text-mobile-7xl font-extrabold text-white md:block md:text-7xl">
              Build a Career With Us
            </div>

            <div className="mb-8 font-heading text-7xl font-bold text-white md:hidden">
              Build a Career With Us
            </div>
            <p className="mb-10 text-xl text-white md:mb-3 md:text-[1.375rem] md:leading-[2rem]">
              Are you an expert at selling mortgage loans? Do you value
              exceptional customer service and thrive in a team environment?
              Join our team and see how far Right Start Mortgage can take you!
            </p>
            <div className="flex flex-wrap items-center space-y-6 md:flex-nowrap md:space-y-0 md:space-x-4">
              <ButtonSolid
                href="https://www.paycomonline.net/v4/ats/web.php/jobs?clientkey=6294EDB598DA7BAF2CEBDC55E8EF6722"
                text="Apply Now"
                altStyle={2}
                className="w-full md:w-auto"
              />
            </div>
          </div>
        </div>
      </section>

      <CareerProps />
      <section className="mb-20 md:mb-32">
        <div className="container">
          <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="order-2 md:order-1">
              <StaticImage
                src="../images/careers/careers-img-1.png"
                loading="lazy"
                width={560}
              />
            </div>
            <div className="order-1 md:order-2">
              <h3 className="heading-four">Bring Home Great Pay & Benefits</h3>
              <p>
                Work for a leading employer that provides you with excellent pay
                and a full benefits package. You’ll receive:
              </p>
              <ul className="styled-list-checkmark">
                <li>
                  Aggressive Compensation Structure for Funded Loans + Hourly
                  Wage(no draw)
                </li>
                <li>Bonuses</li>
                <li>401K With Company Match</li>
                <li>Medical, Dental & Vision</li>
                <li>Paid Time Off</li>
                <li>Pet Insurance</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="relative overflow-hidden py-16 md:py-20">
        <div className="absolute left-0 top-0 h-full w-full ">
          <StaticImage
            src="../images/reviews/Hero.jpg"
            loading="eager"
            className="h-full"
          />
        </div>
        <div className="container">
          <div className="relative mx-auto max-w-[940px] text-center">
            <img src={Quote} alt="Quotation mark" className="mx-auto mb-12" />

            <p className="text-white text-base text-opacity-90 leading-normal">
“I have been a Mortgage Loan Officer for 25 years, and I can finally say "I'm home". I flittered from company to company because I couldn't find one that backed the Loan Officer so well. Loans get done! On time or before! Love the manager, and the employees I work with. This is a family oriented company, and I love it!”
            </p>
            <img src={glassDoor} alt="Quotation mark" className="mx-auto mb-12" />
<p className="text-sm uppercase text-opacity-70 text-white">Mortgage Loan Officer in Tempe, AZ</p>
            <ButtonSolid href="https://www.paycomonline.net/v4/ats/web.php/jobs?clientkey=6294EDB598DA7BAF2CEBDC55E8EF6722" altStyle={2} text="Apply Today" />
          </div>
        </div>
      </section>

      <section className="relative mb-20 bg-primary-50 py-48 md:mb-32 md:py-48">
        <ParallaxProvider>
          <div className="absolute left-[5vw] -top-8 md:-top-7">
            <Parallax speed={-5}>
              <div className="rounded-full border-2 border-primary-500 p-1.5 md:p-2.5">
                <StaticImage
                  src="../images/10.0 About/Team Bubbles/Group 146.png"
                  width={144}
                  className="w-[79px] md:w-[134px]"
                />
              </div>
            </Parallax>
          </div>

          <div className="absolute left-[45vw] top-28 md:top-28 md:left-[20vw]">
            <Parallax translateX={["-40px", "0px"]}>
              <div className="rounded-full border-2 border-secondary-500 p-1.5 md:p-2.5">
                <StaticImage
                  src="../images/10.0 About/Team Bubbles/Group 145.png"
                  width={144}
                  className="w-[51px] md:w-[87px]"
                />
              </div>
            </Parallax>
          </div>

          <div className="absolute -left-8 top-32 md:top-auto md:left-[2vw] md:bottom-20">
            <Parallax translateX={["60px", "0px"]}>
              <div className="rounded-full border-2 border-secondary-500 p-1.5 md:p-2.5">
                <StaticImage
                  src="../images/10.0 About/Team Bubbles/Group 147.png"
                  width={144}
                  className="w-[69px] md:w-[95px]"
                />
              </div>
            </Parallax>
          </div>

          <div className="absolute left-5 bottom-4 z-10 md:left-[35vw] md:-bottom-4">
            <Parallax speed={5}>
              <div className="rounded-full border-2 border-primary-500 p-1.5 md:p-2.5">
                <StaticImage
                  src="../images/10.0 About/Team Bubbles/Group 148.png"
                  width={144}
                  className="w-[73px] md:w-[124px]"
                />
              </div>
            </Parallax>
          </div>

          <div className="absolute right-[15vw] bottom-0 md:-bottom-10 md:right-[25vw]">
            <Parallax speed={-7}>
              <div className="rounded-full border-2 border-secondary-500 p-1.5 md:p-2.5">
                <StaticImage
                  src="../images/10.0 About/Team Bubbles/Group 142.png"
                  width={144}
                  className="w-[56px] md:w-[95px]"
                />
              </div>
            </Parallax>
          </div>

          <div className="absolute bottom-28 -right-2 md:bottom-28 md:right-[4.5vw]">
            <Parallax translateX={["40px", "0px"]}>
              <div className="rounded-full border-2 border-primary-500 p-1.5 md:p-2.5">
                <StaticImage
                  src="../images/10.0 About/Team Bubbles/Group 143.png"
                  width={144}
                  className="w-[71px] md:w-[120px]"
                />
              </div>
            </Parallax>
          </div>

          <div className="absolute left-[40vw] bottom-24 md:bottom-auto md:left-auto md:right-[19vw] md:top-36">
            <Parallax translateX={["-50px", "0px"]}>
              <div className="rounded-full border-2 border-secondary-500 p-1.5 md:p-2.5">
                <StaticImage
                  src="../images/10.0 About/Team Bubbles/Group 149.png"
                  width={144}
                  className="w-[48px] md:w-[82px]"
                />
              </div>
            </Parallax>
          </div>

          <div className="absolute top-22 right-0 md:top-12 md:right-[8vw]">
            <Parallax speed={5}>
              <div className="rounded-full border-2 border-primary-500 p-1.5 md:p-2.5">
                <StaticImage
                  src="../images/10.0 About/Team Bubbles/Group 144.png"
                  width={144}
                  className="w-[56px] md:w-[116px]"
                />
              </div>
            </Parallax>
          </div>

          <div className="absolute top-22 right-0 md:-top-2 md:right-[42vw]">
            <Parallax speed={5}>
              <div className="rounded-full border-2 border-secondary-500 p-1.5 md:p-2.5">
                <StaticImage
                  src="../images/10.0 About/Team Bubbles/Group 150.png"
                  width={144}
                  className="w-[51px] md:w-[87px]"
                />
              </div>
            </Parallax>
          </div>
        </ParallaxProvider>

        <div className="container relative">
          <header className="mx-auto max-w-[640px] text-center">
            <h2>Meet the Team</h2>
            <p>
              We’re a group of mortgage professionals dedicated to making your
              loan process a smooth one. Our goal is to match you with the right
              loan, find you the best interest rate, and help you reach your
              objective, whether you want to own a home or refinance an existing
              loan.
            </p>
            <ButtonSolid href="/meet-the-team/" text="Meet the Team" />
          </header>
        </div>
      </section>
      <section className="relative overflow-hidden py-20 md:py-28">
        <div className="absolute left-0 top-0 h-full w-full bg-primary-900"/>

        <div className="container flex justify-between items-center flex-wrap md:flex-nowrap">
          <div className="relative max-w-[640px] grid gap-y-8 overflow-hidden">
            <h2 className="mb-0 text-white">The Time is Now</h2>
            <p className="text-white">You’re a skilled and talented mortgage loan officer. We’re a team that needs you. Opportunity awaits—let’s connect!
</p>
          </div>
           <div><ButtonSolid href="https://www.paycomonline.net/v4/ats/web.php/jobs?clientkey=6294EDB598DA7BAF2CEBDC55E8EF6722" altStyle={2} text="Apply Today" /></div>           

        </div>
      </section>
    </Layout>
  );
};

export const data = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Open Graph/Facebook/Homepage.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Open Graph/Twitter/Homepage.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
