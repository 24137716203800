import React from "react";
import { useStaticQuery, graphql } from "gatsby";

const CareerProps = ({ headingLevel, className }) => {
  const data = useStaticQuery(graphql`
    {
      icon1: file(
        relativePath: {
          eq: "0.0 Repeating Modules/Why Us/Personalized Experience.svg"
        }
      ) {
        publicURL
      }
      icon2: file(
        relativePath: {
          eq: "0.0 Repeating Modules/Why Us/Easy Loan Process.svg"
        }
      ) {
        publicURL
      }
      icon3: file(
        relativePath: {
          eq: "0.0 Repeating Modules/Why Us/Family Owned _ Operated.svg"
        }
      ) {
        publicURL
      }
      icon4: file(
        relativePath: {
          eq: "0.0 Repeating Modules/Why Us/Experts Since 1989.svg"
        }
      ) {
        publicURL
      }
    }
  `);

  const HeadingTag = headingLevel || "h2";

  const content = [
    {
      icon: data.icon1.publicURL,
      heading: "Third Party Aggregator",
      text: "We’ll fill your pipeline with leads that come straight from top mortgage lead providers.",
    },
    {
      icon: data.icon2.publicURL,
      heading: "Direct Mail Leads",
      text: "You’ll be able to capture additional business from our successful direct mail campaigns.",
    },
    {
      icon: data.icon3.publicURL,
      heading: "Trigger Leads",
      text: "You can help consumers discover Right Start Mortgage through the trigger leads we provide. ",
    },

  ];

  return (
    <section className={`${className || "mb-20 md:mb-32"}`}>
      <div className="container">
        <header className="mb-10 md:mb-16">
          <HeadingTag>Get a Built-in Mortgage Pipeline</HeadingTag>
        </header>

        <div className="relative z-10 grid gap-y-10 md:grid-cols-3 md:gap-x-10 lg:gap-x-20">
          {content.map((content, i) => {
            return (
              <div key={i}>
                <img
                  src={content.icon}
                  alt={content.heading}
                  className="mb-3"
                />
                <div>
                  <p className="heading-six mb-3">{content.heading}</p>
                  <p className="mb-0">{content.text}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default CareerProps;
